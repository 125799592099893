import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Font18 from "components/Fonts/Font18"
import Font12 from "components/Fonts/Font12"
import { Grid } from "@mui/material"
import Avatar from "components/Avatar/Avatar"
import { options } from "constants/richTextOptions"

const PostContent = ({ content, author, createdAt }) => {
  return (
    <Grid mt={4}>
      <article>{renderRichText(content, options)}</article>
      <Grid container justifyContent="end" mt={5}>
        <Grid
          item
          container
          justifyContent="end"
          alignItems="end"
          direction={"column"}
        >
          <Grid item container mb={1} justifyContent="flex-end">
            <Avatar src={author.avatar.file.url} />
          </Grid>
          <Grid item container>
            <Font18
              textAlign="right"
              fw={900}
              ml={1}
              mb={2}
              sx={{ width: "100%" }}
            >
              {author.name} {author.surname}
            </Font18>
            <Font12 textAlign="right" mt={-1} mb={0} sx={{ width: "100%" }}>
              <i>{author.position}</i>
            </Font12>
            <Font12 textAlign="right" sx={{ width: "100%" }}>
              {createdAt}
            </Font12>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PostContent
