import styled from "styled-components"

export const Container = styled.div`
  background-color: rgba(59, 178, 196, 0.65);
  padding: 0 5%;
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Dot = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  margin: 0 15px;
`
