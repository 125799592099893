import React from "react"
import Layout from "hoc/Layout"
import { graphql } from "gatsby"
import PostHeader from "../containers/PostDetails/PostHeader/PostHeader"
import { Container } from "@mui/material"
import PostContent from "../containers/PostDetails/PostContent/PostContent"
import PrivateSite from "../hoc/PrivateSite"

const NewsPostTemplate = ({ data, pageContext }) => {
  const postData = data.contentfulPostAktualnosci

  const {
    title,
    mainImage: {
      file: { url },
    },
  } = postData
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    {
      title: "Neuigkeiten",
      link: "/neuigkeiten",
    },
    `${title}`,
  ]
  const password = pageContext.password

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle={`${title}`}
      containerSize={"xl"}
      seoDescription={"Neuigkeiten Medira"}
      seoThumbnail={`https:${url}`}
    >
      <PrivateSite password={password}>
        <PostHeader {...postData} />
        <Container maxWidth="md">
          <PostContent {...postData} />
        </Container>
      </PrivateSite>
    </Layout>
  )
}

export const query = graphql`
  query NewsPostDetails($contentful_id: String) {
    contentfulPostAktualnosci(contentful_id: { eq: $contentful_id }) {
      author {
        name
        surname
        position
        avatar {
          file {
            url
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              backgroundColor: "transparent"
              placeholder: BLURRED
              width: 852
            )
          }
        }
      }
      createdAt(formatString: "DD.MM.yyyy")
      mainImage {
        gatsbyImageData(
          backgroundColor: "transparent"
          quality: 90
          placeholder: BLURRED
        )
        file {
          url
        }
      }
      title
    }
  }
`

export default NewsPostTemplate
