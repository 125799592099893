import { getImage } from "gatsby-plugin-image"
import React from "react"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { Container, Dot } from "./style"
import Font42 from "components/Fonts/Font42"
import Font22 from "components/Fonts/Font22"
import { useMediaQuery, useTheme } from "@mui/material"

const PostHeader = ({ mainImage, title, createdAt, author }) => {
  const image = getImage(mainImage)
  const bgImage = convertToBgImage(image)

  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    <BackgroundImage
      Tag="header"
      {...bgImage}
      preserveStackingContext
      style={{ margin: isSm ? "0 -24px" : "0 -16px" }}
    >
      <Container>
        <Font42 color="white">{title}</Font42>
        <Font22
          color="white"
          fw={400}
          style={{ display: "flex", alignItems: "center" }}
        >
          {createdAt}
          <Dot />
          {author.name} {author.surname}
        </Font22>
      </Container>
    </BackgroundImage>
  )
}

export default PostHeader
